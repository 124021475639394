
<template>
  <a @click="go2Map()">
    <ion-icon slot="start" :icon="locationOutline"></ion-icon>
    {{ address }}
  </a>
</template>

<script setup>
import { locationOutline } from "ionicons/icons";

import { defineProps } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useMapStore } from "../../state";

const router = useRouter();
const props = defineProps(["address", "centre"]);
const mapStore = useMapStore();

const go2Map = () => {
  mapStore.selectedArea = props.centre;
  mapStore.isSheetModalOpen = true;
  router.push({ name: "map" });
};
</script>

<style scoped>
a {
  font-size: 0.8em;
}
</style>