<template>
  <div>
    <iframe height="100%" width="100%" :src="`${getFilePath}`" />
  </div>
</template>

<script>
// ***** we need to add "localhost:8080" to lib/web/viewer.js to HOSTED_VIEWER_ORIGINS array
export default {
  name: "PDFJSViewer",
  props: {
    path: String,
    name: String,
  },
  computed: {
    getFilePath: function () {
      if (this.path !== "") {
        //return this.path +'?file=' + this.fileName
        // https://firebasestorage.googleapis.com/v0/b/terratonepull.appspot.com/o/corporatesearch%2FABC.D%20TURF%20LTD.%20(2017).pdf?alt=media&token=bdba0ddd-1a8d-44eb-8ff3-473641ee0ea1
        //return "https://firebasestorage.googleapis.com/v0/b/terratonepull.appspot.com/o/corporatesearch%2FABC.D%20TURF%20LTD.%20(2017).pdf?alt=media&token=bdba0ddd-1a8d-44eb-8ff3-473641ee0ea1&embedded=true"
        console.log("path", this.path);
        console.log("file", this.name);
        //this.name="https://firebasestorage.googleapis.com/v0/b/terratonepull.appspot.com/o/corporatesearch%2FABC.D%20TURF%20LTD.%20(2017).pdf?alt% 3Falt% 3Dmedia% 26token% 3Dbdba0ddd-1a8d-44eb-8ff3-473641ee0ea1"
        return this.path + "?file=" + this.name;
      }
      return this.path;
    },
  },
};
</script>
<style scoped>
div {
  width: 100%;
  height: 88%;
}
</style>
