<template>
  <ion-page>
    
    <ion-content>
    
      <MapPage/>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import MapPage from "@/components/map/olmap2New.vue"

export default defineComponent({
  components: { MapPage, IonContent, IonPage },
  name: 'MapTab',
  ionViewDidEnter() {
    console.log('T1 Home page did enter');
  },
  ionViewDidLeave() {
    console.log('T1 Home page did leave');
  },
  ionViewWillEnter() {
    console.log('T1 Home page will enter');
  },
  ionViewWillLeave() {
    console.log('T1 Home page will leave');
  },
  setup() {
    // const authStore = useAuthStore();
    // const imageStore = useImageStore();


    // onMounted(async () => {
    //   await imageStore.initializeCollectionListener("ImageInfo")
    // })

    // return {
    //   authStore, imageStore
    // }
  }
})
</script>
