<template>
  <ion-icon
    v-if="socialStore.checkIsPostLiked(post.id)"
    class="ion-padding-horizontal"
    :icon="heart"
    @click="toggleLikeUnLike(post.id, post.likes)"
  ></ion-icon>
  <ion-icon
    v-else
    class="ion-padding-horizontal"
    :icon="heartOutline"
    @click="toggleLikeUnLike(post.id, post.likes)"
  ></ion-icon>
  <ion-icon
    class="ion-padding-horizontal"
    :icon="chatbubblesOutline"
    @click="viewPostComment(post)"
  ></ion-icon>
</template>

<script setup>
import {
  heart,
  heartOutline,
  mapOutline,
  copyOutline,
  chatbubblesOutline,
} from "ionicons/icons";

import { reactive, onMounted, defineProps } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useSocialStore, useMapStore } from "@/state";

const router = useRouter();
const props = defineProps(["post"]);
const socialStore = useSocialStore();
const mapStore = useMapStore();

// const currentPost = ref({})
// console.log("currentPost", currentPost)

const viewPostComment = (post) => {
  console.log("viewPost", post.id);
  socialStore.selectedPost = post;
  router.push({ name: "post-comment", params: { postId: post.id } });
};

const toggleLikeUnLike = (postId, postLikes) => {
  console.log("toggleLikeUnLike");
  socialStore.toggleLikePost(postId);
  // if (likedPosts.value.indexOf(postId) != -1) {
  //     unlikePost(postId, postLikes);
  // } else {
  //     likePost(postId, postLikes);
  // }
};

const copy = (postContent) => {
  navigator.clipboard
    .writeText(postContent)
    .then(() => {
      alert("Post content is copied.");
      console.log("Text is on the clipboard.");
    })
    .catch((e) => {
      console.error(e);
    });
};

const getLikedUserList = (postid) => {
  console.log("getLikedUserList.");
};

const go2Map = (post) => {
  console.log("post", post, post.centre);
  mapStore.selectedArea = post.centre;
  mapStore.isSheetModalOpen = true;
  router.push({ name: "map" });
  // document.getElementById("maintabbar").setActiveTab(0);
};
</script>

<style scoped>
.postlistitem {
  padding-left: 0px;
}

.commentcontent {
  font-size: 13px;
}
.timestamp {
  font-size: 10px;
  font-style: italic;
  color: green;
  float: right;
}
textarea {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  width: 80%;
  resize: none;
}

.subdiv {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}

* {
  box-sizing: border-box;
}

form {
  display: flex;
  padding: 1%;
  margin: 1%;
}

.box {
  border-radius: 25px;
}

#txtPostSearch {
  background-size: 20px;
  background-position: 4px 10px;
  background-repeat: no-repeat;
  width: 97%;
  padding: 0px 5px 0px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  margin-bottom: 2px;
  margin-left: 5px;
  display: block;
  line-height: 40px;
}

#txtPostSearch::placeholder {
  font-size: 12px;
}
</style>
