<template>
  <ion-item>
    <ion-input
      v-model.trim="comment.content"
      placeholder="Write a comment..."
    ></ion-input>
    <ion-icon
      @click="addComment(props.postId, $event)"
      :icon="sendOutline"
    ></ion-icon>
    <ion-popover
      :is-open="popoverOpen"
      :event="event"
      side="left"
      alignment="start"
      @didDismiss="popoverOpen = false"
    >
      <ion-content class="ion-padding">Comment is empty</ion-content>
    </ion-popover>
  </ion-item>
</template>

<script setup>
import { reactive, computed, watch, ref, defineProps } from "vue";
import { sendOutline } from "ionicons/icons";
import { IonInput, IonItem, IonContent, IonPopover, IonIcon } from "@ionic/vue";
import { useSocialStore } from "../../state";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";

// const inputComment = ref("")
// const inputMedia = ref(null)

const popoverOpen = ref(false);
const event = ref();
const comment = reactive({
  postId: "",
  userId: "",
  content: "",
  postComments: 0,
});
const props = defineProps(["postId"]);
const socialStore = useSocialStore();

const addComment = (postId, e) => {
  if (comment.content.trim() == "") {
    popoverOpen.value = true;
    event.value = e;
  } else {
    socialStore.addComment(postId, comment);
  }
};
</script>

<style scoped>
</style>

