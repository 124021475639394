
import { db } from "@/firebase";
import { 
    collection, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, 
    onSnapshot, query, where, increment, Timestamp, getDoc, orderBy, limit, 
    writeBatch, 
} from "firebase/firestore";
import _ from "lodash"
import union from "@turf/union";
import booleanEqual from "@turf/boolean-equal";
import * as turfHelpers from "@turf/helpers";
import { defineStore } from 'pinia'
import { useAuthStore } from "@/router/useAuthStore"

export const useLandMergerStore = defineStore('landMerger', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      docIds: [],
      mergedPIDs: [],
      lastVertices: [],
      isMultipleSelectionOn: false
    }
  },
  getters: {
  }, 
  actions: {
    async fetchVertices(newVertices, PID, callback) {
      const querySnapshot = await getDocs(query(collection(db, "mergedlands"), where("PIDs", "array-contains", PID), limit(1)));

      let docId = null;
      let PIDs = [PID];
      let vertices = [];
      // if(!querySnapshot.empty){
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log("fetchVertices", doc.id, " => ", doc.data());
          docId = doc.id;
          PIDs = doc.data().PIDs;
          let x = doc.data().x;
          let y = doc.data().y;
          for (let i = 0; i < x.length; ++i) {
            vertices.push([x[i], y[i]]);
          }
        })
      // }

      if (vertices.length > 0) {
        console.log('vertices.length > 0', vertices)
        newVertices = [vertices];
      }

      if (newVertices == null || newVertices.length == 0) {
        // callback(storedVertices);
        console.log("callback(storedVertices)")
        return;
      }

      console.log('newVertices', newVertices)
      console.log('this.lastVertices', this.lastVertices)

      if (this.isMultipleSelectionOn && this.lastVertices.length > 0) {
        let prevPoly = turfHelpers.polygon(this.lastVertices);
        let newPoly = turfHelpers.polygon(newVertices);
        let resultPoly = union(prevPoly, newPoly);
        if (resultPoly.geometry.type == "Polygon") {
          if (!booleanEqual(prevPoly, resultPoly)) {
            this.storePolygon(resultPoly.geometry.coordinates);
            this.mergedPIDs = this.mergedPIDs.concat(PIDs);
            this.docIds.push(docId);  // if there is no docId, a null is pushed into docIds
          }
          callback(resultPoly.geometry.coordinates);
          return;
        }
      }

      this.storePolygon(newVertices);
      this.mergedPIDs = PIDs;
      this.docIds = [docId];
      callback(newVertices);
      
    },
    storePolygon(vertices) {
      
      // Stores a sallow copy of the vertices array in storedVertices
      this.lastVertices = [];
      for (let v of vertices) {
        this.lastVertices.push(v.slice());
      }
    },
    saveResult() {
      const authStore = useAuthStore()
      if (this.lastVertices.length == 0)
        return;
      
      let x = [];
      let y = [];
      for (let coordinates of this.lastVertices[0]) {
        x.push(coordinates[0]);
        y.push(coordinates[1]);
      }

      const batch = writeBatch(db)
      var mergedlandsRef = collection(db, "mergedlands")
      // let batch = firebase.db.batch();
      for (let docId of this.docIds) {
        if (docId) {
          batch.delete(doc(mergedlandsRef, docId));
        }
      }
      
      let newDocRef = doc(mergedlandsRef)
      batch.set(newDocRef, {
        createdOn: Timestamp.now(),
        createdBy: authStore.currentUser.uid,
        PIDs: this.mergedPIDs,
        x: x,
        y: y,
      })

      return batch.commit().then(() => {
        this.docIds = [newDocRef.id];
      })
    }, 
    deleteResult() {
      const batch = writeBatch(db)
      var mergedlandsRef = collection(db, "mergedlands")

      for (let docId of this.docIds) {  // there should only be one element in this.docIds
        if (docId) {
          batch.delete(doc(mergedlandsRef, docId))
        }
      }

      return batch.commit().then(() => {
        this.docIds = [];
        for (let i = 0; i < this.mergedPIDs.length; ++i) {
          this.docIds.push(null);
        }
      })
    }
  }
})